/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import queryString from 'querystring';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Link from 'next/link';
import { useStripe } from '@stripe/react-stripe-js';
import Router from 'next/router';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Tooltip from '@mui/material/Tooltip';
import { v4 as uuidv4 } from 'uuid';
import { gtmEvents } from 'utils/gtm.ts';
import Cookies from 'lib/js-cookie';
import PublicTopNavigation from 'components/Navigation/PublicTopNavigation';
import PublicFooter from 'components/Footer/PublicFooter';
import { getProfileData } from 'apis/profile';
import { cancelSubscription, renewSubscription } from 'apis/subscription';
import SharePopup from 'components/Toast/SharePopup';
import ConfirmationPopup from 'components/Toast/ConfirmationPopup';
import { detectMobileBrowser } from 'components/Utilities';
import PodcastSubscription from 'components/SubscriberForms/PodcastSubscription';
import { getCurrency, translate as t } from 'utils/helpers';
import UserContext from 'context/UserContext';
import styles from 'pages/[podcast-name]/SinglePublicPodcast.module.scss';
import routes from 'utils/routes';
import MessagePopup from 'components/Toast/MessagePopup';
import {
  getStripePurchaseHandler,
  getSubscriptionUpgradeHandler,
} from 'utils/stripe';
import useCoupon from 'hooks/useCoupon';
import useLang from '../../context/LanguageContext';
import usePodcastEpisodeListHook from '../../hooks/usePodcastEpisodeListHook';
import {
  initializeSpotifyLinking,
  spotifyEntryPointChecker,
  validateSpotifyLinking,
} from '../../apis/spotify';

const renderSpinner = () => <Spinner animation="grow" />;

const PodcastPage = ({
  podcastData,
  episodeData,
  isPodcast,
  host,
  slug,
  token,
  subscriptionConfirmation,
  spotifyShowUri,
}) => {
  const [userData, setUserData] = useContext(UserContext);
  const podcaster = parseInt(Cookies.get('podcaster'), 10);
  const { lang } = useLang();
  const siteLang = podcastData?.creator_language;

  const { coupon, isCouponValid, discountedPriceObject } =
    useCoupon(podcastData);
  const stripe = useStripe();

  const [profileData, setProfileData] = useState({});
  const [showPlanSelectModal, setShowPlanSelectModal] = useState(false);
  const [isAnnualSubscriber, setIsAnnualSubscriber] = useState(
    podcastData?.annual_subscriber || false,
  );

  const [showConfirmationBox, setShowConfirmationBox] = useState(
    subscriptionConfirmation === slug,
  );
  const [userSubscribed, setUserSubscribed] = useState(false);
  const [unsubscribeLoading, setUnsubscribeLoading] = useState(false);
  const [message, setMessage] = useState(
    isPodcast
      ? {
          type: subscriptionConfirmation === slug ? 'subscribe' : '',
          title:
            subscriptionConfirmation === slug
              ? t(siteLang, 'podcast')('subscribeTitle')
              : '',
          message:
            subscriptionConfirmation === slug
              ? userData.isCardExpired
                ? t(siteLang, 'podcast')('cardExpiredMessage')
                : t(siteLang, 'podcast')('subscribeDescription')
              : '',
          cardExpiresMessage: userData.isCardExpired,
        }
      : {
          type: subscriptionConfirmation === slug ? 'purchase' : '',
          title:
            subscriptionConfirmation === slug
              ? t(siteLang, 'podcast')('subscribeTitle')
              : '',
          message:
            subscriptionConfirmation === slug
              ? userData.isCardExpired
                ? t(siteLang, 'podcast')('cardExpiredMessageAudioSeries')
                : t(siteLang, 'podcast')('subscribeDescription')
              : '',
          cardExpiresMessage: userData.isCardExpired,
        },
  );
  const [showSubscriptionMessage, setShowSubscriptionMessage] = useState(false);

  const [listOfEpisode, setListOfEpisode] = useState(() => {
    if (episodeData?.results) {
      return episodeData.results.filter((item) => item.type !== 2);
    }
    return [];
  });
  const showSubscribeButtons = !podcastData?.hide_subscribe_buttons;
  // const [loadingEpisodes, setLoadingEpisodes] = useState(false);
  // const [enableMoreBtn, setEnableMoreBtn] = useState(
  //   () => episodeData?.next || false,
  // );
  const [modalShow, setModalShow] = useState(false);
  const [subBtnLoading, setSubBtnLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const currency = getCurrency(podcastData?.currency);

  const [redirectUrl, setRedirectUrl] = useState(null);

  const showListenNowSection =
    podcastData?.apple_podcast ||
    podcastData?.spotify ||
    podcastData?.overcast ||
    podcastData?.ivoox ||
    podcastData?.youtube;

  const [pageNumber, setPageNumber] = useState(1);
  const [entryPointCheckerError, setEntryPointCheckerError] = useState('');

  const observer = useRef();
  const {
    loading,
    audios,
    // setAudios,
    // trailer,
    // setTrailerEpisode,
    hasMore,
    setHasMore,
  } = usePodcastEpisodeListHook(pageNumber, isPodcast, podcastData?.id);

  const lastAudioElementRef = useCallback(
    (node) => {
      if (loading) return;
      // eslint-disable-next-line no-unused-expressions
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevNum) => prevNum + 1);
        } // TODO: Adjust Root margins if needed
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore],
  );

  // const trailer = episodeData?.trailer;
  const [trailerEpisode, setTrailerEpisode] = useState(episodeData?.trailer);

  useEffect(() => {
    if (window !== undefined) {
      const temp = window?.location?.href;
      setRedirectUrl(temp);
    }
  }, []);

  useEffect(() => {
    if (episodeData) {
      setListOfEpisode(
        episodeData?.results
          ? episodeData.results.filter((item) => item.type !== 2)
          : [],
      );
      setTrailerEpisode(episodeData?.trailer);
      setHasMore(episodeData?.next);
    }
  }, [episodeData]);

  useEffect(() => {
    if (audios?.length) {
      setListOfEpisode(audios);
    }
  }, [audios]);

  function chooseSubscriptionPlan() {
    setShowPlanSelectModal(true);
  }

  useEffect(async () => {
    const newSubbed = Cookies.get('new_sub');
    if (newSubbed === podcastData?.slug) {
      setUserData((prevState) => ({
        ...prevState,
        showModal: true,
        title: t(siteLang, 'podcast')('podcastWelcomeDialogTitle'),
        message: t(siteLang, 'podcast')('podcastWelcomeDialogMessage', {
          name: podcastData?.creator_name,
        }),
        submitButtonColor: '#0d122a',
        submitButton: t(siteLang, 'common')('share'),
        showSubmitButton: true,
        confirmActionCallback: openSharingBox,
      }));
      setUserSubscribed(true);
      if (process.env.NEXT_PUBLIC_FRONTEND_URL === 'http://localhost:3000') {
        Cookies.remove('new_sub');
      } else {
        Cookies.remove('new_sub', { path: '/', domain: '.mumbler.io' });
      }
      if (spotifyShowUri) {
        const cookieCode = Cookies.get('soa_code');
        // call entrypoint url
        const params = queryString.stringify({
          spotify_show_url: spotifyShowUri,
        });
        const spotifyResponse = await spotifyEntryPointChecker(params);
        if (spotifyResponse?.status === 200) {
          // call api to get completion url
          const spotifyPayload = {
            spotify_show_url: spotifyShowUri,
          };
          if (!spotifyResponse?.data?.data?.is_soa_linked) {
            spotifyPayload.code = cookieCode;
          }
          const spotifyLinkingResponse = await initializeSpotifyLinking(
            spotifyPayload,
          );

          if (spotifyLinkingResponse?.status === 200) {
            if (
              process.env.NEXT_PUBLIC_FRONTEND_URL === 'http://localhost:3000'
            ) {
              Cookies.remove('soa_state', { path: '/' });
              Cookies.remove('spotifyShowUri', { path: '/' });
              Cookies.remove('soa_isSoaLinked', { path: '/' });
              Cookies.remove('soa_code', { path: '/' });
            } else {
              Cookies.remove('soa_state', {
                path: '/',
                domain: '.mumbler.io',
              });
              Cookies.remove('spotifyShowUri', {
                path: '/',
                domain: '.mumbler.io',
              });
              Cookies.remove('soa_isSoaLinked', {
                path: '/',
                domain: '.mumbler.io',
              });
              Cookies.remove('soa_code', {
                path: '/',
                domain: '.mumbler.io',
              });
            }
            Router.push(
              spotifyLinkingResponse?.data?.data?.spotify_completion_url,
            );
          } else {
            toast.error(spotifyLinkingResponse?.data?.message);
            if (
              process.env.NEXT_PUBLIC_FRONTEND_URL === 'http://localhost:3000'
            ) {
              Cookies.remove('soa_state', { path: '/' });
              Cookies.remove('spotifyShowUri', { path: '/' });
              Cookies.remove('soa_isSoaLinked', { path: '/' });
              Cookies.remove('soa_code', { path: '/' });
            } else {
              Cookies.remove('soa_state', {
                path: '/',
                domain: '.mumbler.io',
              });
              Cookies.remove('spotifyShowUri', {
                path: '/',
                domain: '.mumbler.io',
              });
              Cookies.remove('soa_isSoaLinked', {
                path: '/',
                domain: '.mumbler.io',
              });
              Cookies.remove('soa_code', {
                path: '/',
                domain: '.mumbler.io',
              });
            }
          }
        } else {
          // show entrypoint checker url
          if (
            spotifyResponse?.data?.message?.spotify_show_url?.non_field_errors
          ) {
            toast.error(
              t(
                lang,
                'spotify',
              )('somethingWentWrongPleaseContactMumblerByUsingTheWebChat'),
            );
          } else {
            toast.error(t(lang, 'helpError')('somethingWentWrong'));
          }
        }
      }
    }
  }, [podcastData, userData]);

  useEffect(() => {
    if (
      userData?.reqForBtn?.includes('prev') ||
      userData?.reqForBtn?.includes('next')
    ) {
      let playList = listOfEpisode;
      if (
        !podcastData?.user_subscribed &&
        podcastData?.creator !== podcaster &&
        !(podcastData?.free_podcast || parseFloat(podcastData?.price) === 0)
      ) {
        playList = listOfEpisode?.filter((item) => item.type !== 1);
      }
      const currentIndex = playList
        .map((item) => item.id)
        .indexOf(userData?.episodeDetail.id);
      let episodeObj = playList[currentIndex - 1];
      if (userData?.reqForBtn?.includes('next'))
        episodeObj = playList[currentIndex + 1];
      if (episodeObj?.id) {
        // ({ id: episodeObj?.id, playStatus: true });
        setUserData((prevState) => ({
          ...prevState,
          showPlayer: true,
          audioStatus: true,
          episodeDetail: {
            url:
              episodeObj?.type === 1 &&
              !podcastData?.user_subscribed &&
              !(
                podcastData?.free_podcast ||
                parseFloat(podcastData?.price) === 0
              )
                ? episodeObj?.promo_presined_url
                : episodeObj?.presigned_url_get_object,
            episode_name: episodeObj?.name,
            hosted_by: podcastData?.creator_name,
            cover: episodeObj?.cover_webp,
            podcast_name: podcastData?.title,
            podcast_slug: podcastData?.slug,
            description: episodeObj?.description,
            id: episodeObj?.id,
            audio_type: episodeObj?.audio_type,
            episode_redirect_url: episodeObj?.domain_redirect_url,
            creator_domain_url: podcastData?.creator_page_url,
          },
        }));
      } else {
        // if (!userData?.audioStatus && userData?.hasEnded) {
        closePlayer();
        // }
      }
    }
  }, [userData?.reqForBtn]);

  // const fetchMoreEpisodes = async () => {
  //   setLoadingEpisodes(true);
  //   const url = enableMoreBtn;
  //   const response = await episodeList(url, false, {}, true);
  //   if (response?.status === 200) {
  //     setEnableMoreBtn(response?.data?.next);
  //     setListOfEpisode((existingList) => [
  //       ...existingList,
  //       ...response?.data?.results.filter(
  //         (item) => item?.status_display !== 'Unpublished' && item?.type !== 2,
  //       ),
  //     ]);
  //   }
  //   setLoadingEpisodes(false);
  // };

  const closePlayer = () => {
    document?.body?.classList?.remove('audio-player-active');
    if (process.env.NEXT_PUBLIC_FRONTEND_URL === 'http://localhost:3000') {
      Cookies.remove('playerData', { path: '/' });
    } else {
      Cookies.remove('playerData', { path: '/', domain: '.mumbler.io' });
    }
    setUserData((prevState) => ({
      ...prevState,
      showPlayer: false,
      audioPlayStatus: false,
    }));
  };

  const openSharingBox = async () => {
    const isMobBrowser = detectMobileBrowser();
    if (isMobBrowser && navigator?.share) {
      const shareData = {
        url: `${podcastData?.domain_redirect_url}`,
        text: t(siteLang, 'podcast')('podcastShareMessage', {
          link: podcastData?.title,
        }),
        description: podcastData?.description,
      };
      try {
        await navigator.share(shareData);
      } catch (e) {
        if (e.toString().includes('AbortError')) {
          console.log('Share aborted :(');
        }
      }
    } else {
      setModalShow(true);
    }
  };

  // const subscribeToPodcast = (interval = 'monthly') => {
  //   setShowConfirmationBox(false);
  //   const podcaster = Cookies.get('podcaster');
  //   const tkn = Cookies.get('token');
  //   if (podcastData.creator === parseInt(podcaster)) return false;
  //   setSubBtnLoading(true);
  //   gtmEvents.subscriptionStatus({
  //     status: 'paying',
  //     podcast: podcastData,
  //   });
  //   if (tkn) {
  //     checkUserHasAlreadyAddedCard(interval);
  //   } else {
  //     sessionStorage.setItem('has_subscribed_to', JSON.stringify(podcastData));
  //     Router.push(`${routes.register(siteLang)}?podcast=${slug}`);
  //     setSubBtnLoading(false);
  //   }
  // };

  const updateCreditCard = () => {
    Router.push(routes.billing);
  };
  useEffect(() => {
    const tkn = Cookies.get('token');
    const getData = async () => {
      const response = await getProfileData();
      if (response?.status === 200) {
        setProfileData(response?.data);
      } else {
        setProfileData({});
      }
    };
    if (tkn) {
      getData();
    }
    const newSubbed = Cookies.get('new_sub');
    if (newSubbed === podcastData?.slug) {
      setUserSubscribed(true);
      setUserData((prevState) => ({
        ...prevState,
        showModal: true,
        title: t(siteLang, 'podcast')('podcastWelcomeDialogTitle'),
        message: t(siteLang, 'podcast')('podcastWelcomeDialogMessage', {
          name: podcastData?.creator_name,
        }),
        submitButtonColor: '#0d122a',
        submitButton: t(siteLang, 'common')('share'),
        showSubmitButton: true,
        confirmActionCallback: openSharingBox,
      }));
      if (process.env.NEXT_PUBLIC_FRONTEND_URL === 'http://localhost:3000') {
        Cookies.remove('new_sub');
      } else {
        Cookies.remove('new_sub', { path: '/', domain: '.mumbler.io' });
      }
    }
  }, []);
  const planTypeRef = useRef('monthly');
  useEffect(() => {
    const planType = Cookies.get('plan_interval');
    if (planType) {
      if (planType === 'yearly' && podcastData?.annual_price_enabled) {
        planTypeRef.current = planType;
      }
      Cookies.remove('plan_interval');
    }
  }, []);

  function purchaseSuccess() {
    setSubBtnLoading(false);
    setUserData((prevState) => ({
      ...prevState,
      showModal: true,
      title: t(siteLang, 'podcast')('podcastWelcomeDialogTitle'),
      message: t(siteLang, 'podcast')('podcastWelcomeDialogMessage', {
        name: podcastData?.creator_name,
      }),
      submitButtonColor: '#0d122a',
      submitButton: t(siteLang, 'common')('share'),
      showSubmitButton: true,
      confirmActionCallback: openSharingBox,
    }));
    gtmEvents.subscriptionStatus({
      status: 'finished',
      podcast: podcastData,
    });
    if (process.env.NEXT_PUBLIC_FRONTEND_URL === 'http://localhost:3000') {
      Cookies.set('new_sub', slug, { expires: 10, path: '/' });
    } else {
      Cookies.set('new_sub', slug, {
        expires: 10,
        path: '/',
        domain: '.mumbler.io',
      });
    }
    setUserSubscribed(true);
    setIsAnnualSubscriber(planTypeRef.current === 'yearly');
    // Cookies.set('new_sub', slug, { expires: 10, path: '/' })
    if (spotifyShowUri) {
      Router.push(
        `${podcastData?.domain_redirect_url}?spotifyShowUri=${spotifyShowUri}`,
      );
    } else {
      Router.push(podcastData?.domain_redirect_url);
    }
  }

  function purchaseError(error) {
    toast.error(error?.message);
    setSubBtnLoading(false);
  }

  function addPrePurchaseChecks(funct) {
    // eslint-disable-next-line consistent-return
    function inner(...args) {
      setShowConfirmationBox(false);
      const tkn = Cookies.get('token');
      if (podcastData?.creator === podcaster) return false;
      setSubBtnLoading(true);
      gtmEvents.subscriptionStatus({
        status: 'paying',
        podcast: podcastData,
      });
      if (tkn) {
        if (profileData?.stripe_payment_method_id?.length) {
          funct(...args);
        } else
          Router.push(
            `${routes.registerPayment()}?podcast=${slug}${
              isCouponValid ? `&coupon=${coupon.name}` : ''
            }`,
          );
      } else {
        if (process.env.NEXT_PUBLIC_FRONTEND_URL === 'http://localhost:3000') {
          Cookies.set(
            'has_subscribed_to',
            JSON.stringify({
              slug: podcastData.slug,
              domain_redirect_url: podcastData.domain_redirect_url,
            }),
            { path: '/' },
          );
          if (isCouponValid) {
            Cookies.set('coupon', coupon?.name);
          }
        } else {
          Cookies.set(
            'has_subscribed_to',
            JSON.stringify({
              slug: podcastData.slug,
              domain_redirect_url: podcastData.domain_redirect_url,
            }),
            {
              path: '/',
              domain: '.mumbler.io',
            },
          );
          if (isCouponValid) {
            Cookies.set('coupon', coupon?.name, {
              path: '/',
              domain: '.mumbler.io',
            });
          }
        }
        Router.push(
          `${routes.register(siteLang)}?podcast=${slug}${
            isCouponValid ? `&coupon=${coupon.name}` : ''
          }`,
        );
        setSubBtnLoading(false);
      }
    }

    return inner;
  }

  const subscribeToPodcast = addPrePurchaseChecks(
    getStripePurchaseHandler(stripe, purchaseSuccess, purchaseError),
  );
  const upgradeSubscription = addPrePurchaseChecks(
    getSubscriptionUpgradeHandler(stripe, purchaseSuccess, purchaseError),
  );

  const playEpisode = (item) => {
    if (
      item?.type === 1 &&
      !((token && userSubscribed) || podcastData?.user_subscribed) &&
      podcastData?.creator !== podcaster &&
      !item?.promo_presined_url &&
      !(podcastData?.free_podcast || parseFloat(podcastData?.price) === 0)
    ) {
      if (podcastData?.audio_type === 'podcast') {
        setMessage({
          type: 'subscribe',
          title: t(siteLang, 'podcast')('subcribe'),
          message: t(siteLang, 'podcast')('onlyForSubscribersSubscribeNow'),
        });
      } else {
        setMessage({
          type: 'purchase',
          title: t(siteLang, 'podcast')('purchaseAudio'),
          message: t(siteLang, 'podcast')('onlyForCustomerPurchaseNow'),
        });
      }
      if (showSubscribeButtons) setShowConfirmationBox(true);
      else setShowSubscriptionMessage(true);
      return false;
    }

    if (userData?.episodeDetail?.id === item.id) {
      setUserData((prevState) => ({
        ...prevState,
        showPlayer: true,
        audioPlayStatus: !userData?.audioPlayStatus,
      }));
      return false;
    }
    document?.body?.classList?.add('audio-player-active');
    setUserData((prevState) => ({
      ...prevState,
      showPlayer: true,
      audioPlayStatus: true,
      isOnRefresh: false,
      episodeDetail: {
        url:
          item?.type === 1 &&
          !podcastData?.user_subscribed &&
          podcastData?.creator !== podcaster &&
          !(podcastData?.free_podcast || parseFloat(podcastData?.price) === 0)
            ? item?.promo_presined_url
            : item?.presigned_url_get_object,
        episode_name: item?.name,
        hosted_by: podcastData?.creator_name,
        cover: item?.cover_webp,
        podcast_name: podcastData?.title,
        podcast_slug: podcastData?.slug,
        description: item?.description,
        id: item?.id,
        audio_type: item?.audio_type,
        episode_redirect_url: item?.domain_redirect_url,
        creator_domain_url: podcastData?.creator_page_url,
      },
    }));
  };

  const subscribeConfirmation = () => {
    if (token) {
      if (!profileData?.stripe_payment_method_id?.length) {
        if (process.env.NEXT_PUBLIC_FRONTEND_URL === 'http://localhost:3000') {
          Cookies.set(
            'has_subscribed_to',
            JSON.stringify({
              slug: podcastData.slug,
              domain_redirect_url: podcastData.domain_redirect_url,
            }),
            { path: '/' },
          );
          if (isCouponValid) {
            Cookies.set('coupon', coupon?.name);
          }
        } else {
          Cookies.set(
            'has_subscribed_to',
            JSON.stringify({
              slug: podcastData.slug,
              domain_redirect_url: podcastData.domain_redirect_url,
            }),
            {
              path: '/',
              domain: '.mumbler.io',
            },
          );
          if (isCouponValid) {
            Cookies.set('coupon', coupon?.name, {
              path: '/',
              domain: '.mumbler.io',
            });
          }
        }
        if (podcastData?.coupon_data) {
          if (podcastData?.coupon_data?.plan === 'annual') {
            planTypeRef.current = 'yearly';
            if (
              process.env.NEXT_PUBLIC_APP_FRONTEND_URL ===
              'http://localhost:3000'
            ) {
              Cookies.set('plan_interval', planTypeRef?.current, { path: '/' });
            } else {
              Cookies.set('plan_interval', planTypeRef?.current, {
                path: '/',
                domain: '.mumbler.io',
              });
            }
          } else if (
            process.env.NEXT_PUBLIC_APP_FRONTEND_URL === 'http://localhost:3000'
          ) {
            Cookies.remove('plan_interval', { path: '/' });
          } else {
            Cookies.remove('plan_interval', {
              path: '/',
              domain: '.mumbler.io',
            });
          }
        }
        Router.push(
          `${routes.registerPayment()}?podcast=${slug}${
            isCouponValid ? `&coupon=${coupon?.name}` : ''
          }${spotifyShowUri ? `&spotifyShowUri=${spotifyShowUri}` : ''}`,
        );
      } else {
        if (isPodcast) {
          setMessage({
            type: 'subscribe',
            title: t(siteLang, 'podcast')('subscribeTitle'),
            message: userData?.isCardExpired
              ? t(siteLang, 'podcast')('cardExpiredMessage')
              : t(siteLang, 'podcast')('subscribeDescription'),
            cardExpiresMessage: userData?.isCardExpired,
          });
        } else {
          setMessage({
            type: 'purchase',
            title: t(siteLang, 'podcast')('subscribeTitle'),
            message: userData?.isCardExpired
              ? t(siteLang, 'podcast')('cardExpiredMessageAudioSeries')
              : t(siteLang, 'podcast')('subscribeDescription'),
            cardExpiresMessage: userData?.isCardExpired,
          });
        }
        setShowConfirmationBox(true);
      }
    } else {
      if (process.env.NEXT_PUBLIC_FRONTEND_URL === 'http://localhost:3000') {
        Cookies.set(
          'has_subscribed_to',
          JSON.stringify({
            slug: podcastData.slug,
            domain_redirect_url: podcastData.domain_redirect_url,
          }),
          { path: '/' },
        );
        if (isCouponValid) {
          Cookies.set('coupon', coupon?.name);
        }
      } else {
        Cookies.set(
          'has_subscribed_to',
          JSON.stringify({
            slug: podcastData.slug,
            domain_redirect_url: podcastData.domain_redirect_url,
          }),
          {
            path: '/',
            domain: '.mumbler.io',
          },
        );
        if (isCouponValid) {
          Cookies.set('coupon', coupon?.name, {
            path: '/',
            domain: '.mumbler.io',
          });
        }
      }
      if (podcastData?.coupon_data) {
        if (podcastData?.coupon_data?.plan === 'annual') {
          planTypeRef.current = 'yearly';
          if (
            process.env.NEXT_PUBLIC_APP_FRONTEND_URL === 'http://localhost:3000'
          ) {
            Cookies.set('plan_interval', planTypeRef.current, { path: '/' });
          } else {
            Cookies.set('plan_interval', planTypeRef.current, {
              path: '/',
              domain: '.mumbler.io',
            });
          }
        } else if (
          process.env.NEXT_PUBLIC_APP_FRONTEND_URL === 'http://localhost:3000'
        ) {
          Cookies.remove('plan_interval', { path: '/' });
        } else {
          Cookies.remove('plan_interval', {
            path: '/',
            domain: '.mumbler.io',
          });
        }
      }
      Router.push(
        `${routes.register(siteLang)}?podcast=${slug}${
          isCouponValid ? `&coupon=${coupon.name}` : ''
        }`,
      );
      setSubBtnLoading(false);
    }
    gtmEvents.subscriptionStatus({
      status: 'started',
      podcastData,
    });
  };

  const handleRenewSubscription = async () => {
    setSubBtnLoading(true);
    const response = await renewSubscription({ podcast_id: podcastData.id });
    if (
      (response?.status === 200 || response?.status === 201) &&
      response?.data?.success
    ) {
      setUserData((prevState) => ({
        ...prevState,
        showModal: true,
        title: t(siteLang, 'podcast')('renewTitle'),
        message: t(siteLang, 'podcast')('renewMsg'),
        submitButtonColor: '#0d122a',
        submitButton: t(siteLang, 'common')('share'),
        showSubmitButton: true,
        confirmActionCallback: openSharingBox,
      }));
      Router.push(podcastData?.domain_redirect_url);
    }
    setSubBtnLoading(false);
  };

  const unSubscribeConfirmation = () => {
    setMessage({
      type: 'unsubscribe',
      message: t(siteLang, 'common')('unsubscribeQuestion', {
        podcast: podcastData?.title,
        date: moment(podcastData?.subscription_end_date).format('DD/MM/YY'),
      }),
    });
    setShowConfirmationBox(true);
  };

  const unSubscribe = async () => {
    setUnsubscribeLoading(true);
    const payload = {
      podcast: podcastData?.id,
    };
    const response = await cancelSubscription(payload);
    if (response?.status === 200) {
      setShowConfirmationBox(false);
      setUserData((prevState) => ({
        ...prevState,
        showModal: true,
        title: t(siteLang, 'common')('unsubscribedTitle'),
        message: t(siteLang, 'common')('unsubscribedmessage'),
        showSubmitButton: false,
      }));
      Router.push(routes.myFeeds);
    }
    setUnsubscribeLoading(false);
  };

  // const getEpisodeDescription = (description) => {
  //   if (process?.browser) {
  //     const el = document.createElement('p');
  //     el.style.display = 'none';
  //     el.innerHTML = description;
  //     const content = el.textContent;
  //     if (content.length > 130) {
  //       const newContent = content.substring(0, 130);
  //       return newContent.concat('...');
  //     }
  //     return content;
  //   }
  // };

  // const getEpisodeDescriptionLength = (description) => {
  //   if (process?.browser) {
  //     const el = document.createElement('p');
  //     el.style.display = 'none';
  //     el.innerHTML = description;
  //     const content = el.textContent;
  //     const length = content.length > 130;
  //     return length;
  //   }
  // };

  return (
    <div className="page nosidebar">
      <Head>
        <title>Podcast {podcastData?.title}</title>
        {/* <link rel="icon" href="/favicon.png" /> */}
        <meta property="og:title" content={`Podcast ${podcastData?.title}`} />
        <meta property="og:description" content={podcastData?.description} />
        <meta property="og:site_name" content={podcastData?.title} />
        <meta property="og:url" content={podcastData?.domain_redirect_url} />
        <meta property="og:image" content={podcastData?.cover} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content={podcastData?.description} />
        <meta name="twitter:title" content={`Podcast ${podcastData?.title}`} />
        <meta name="twitter:site" content="@username" />
        <meta name="twitter:image" content={podcastData?.cover} />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'PodcastSeries',
              url: `${podcastData?.domain_redirect_url}`,
              name: `${podcastData?.title}`,
              image: `${podcastData?.cover}`,
              description: `${
                podcastData?.description &&
                podcastData?.description.replace(/(<([^>]+)>)/gi, '')
              }`,
            }),
          }}
        />
      </Head>
      {podcastData && (
        <main className="main">
          <Container className="full mobile-full" fluid>
            <Row className={styles.mainRow}>
              <Col lg={12} className={styles.primary}>
                <PublicTopNavigation
                  showLine
                  lang={lang}
                  showAddToPodcatcher={
                    token &&
                    (podcastData?.user_subscribed ||
                      podcastData?.free_podcast ||
                      parseFloat(podcastData?.price) === 0)
                      ? podcastData.slug
                      : false
                  }
                  goToPodcatcherLink={podcastData?.podcatcher_redirect_link}
                  redirectUrl={redirectUrl}
                  // showWordpressLink
                />
                <Container className="wider">
                  <Row className={styles.topSection}>
                    <Col md={5} className={styles.imageWrap}>
                      <img
                        className="img-fluid"
                        src={podcastData?.cover_webp}
                        alt={podcastData?.title}
                        loading="lazy"
                      />
                    </Col>
                    <Col md={7} className={styles.podcastDetailsWrap}>
                      <div className={styles.SinglePodcast}>
                        <div className={styles.titleEditWrap}>
                          <h1 className={styles.mainTitle}>
                            <Link
                              href={podcastData?.domain_redirect_url || '#'}>
                              <a
                                className={`${styles.mainTitleLink} no-effect`}>
                                {podcastData?.title}
                              </a>
                            </Link>
                          </h1>
                        </div>
                        <div className={`${styles.socialIconsWrap} row`}>
                          <div className="col-12 col-lg-7">
                            {podcastData?.twitter_url && (
                              <a
                                className={`${styles.singleIconWrap} no-effect`}
                                href={podcastData?.twitter_url || '#'}
                                rel="noreferrer"
                                target="_blank"
                                style={{
                                  'pointer-events': podcastData?.twitter_url
                                    ? 'auto'
                                    : 'none',
                                }}>
                                <img
                                  src="/social-icons/twitter-icon.svg"
                                  className={styles.singleIcon}
                                  alt=""
                                  loading="lazy"
                                />
                              </a>
                            )}
                            {podcastData?.instagram_url && (
                              <a
                                className={`${styles.singleIconWrap} no-effect`}
                                href={podcastData?.instagram_url || '#'}
                                rel="noreferrer"
                                target="_blank"
                                style={{
                                  'pointer-events': podcastData?.instagram_url
                                    ? 'auto'
                                    : 'none',
                                }}>
                                <img
                                  src="/social-icons/instagram-icon.svg"
                                  className={styles.singleIcon}
                                  alt=""
                                  loading="lazy"
                                />
                              </a>
                            )}
                            {podcastData?.linkedin_url && (
                              <a
                                className={`${styles.singleIconWrap} no-effect`}
                                href={podcastData?.linkedin_url || '#'}
                                rel="noreferrer"
                                target="_blank"
                                style={{
                                  'pointer-events': podcastData?.linkedin_url
                                    ? 'auto'
                                    : 'none',
                                }}>
                                <img
                                  src="/social-icons/linkedin-icon.svg"
                                  className={styles.singleIcon}
                                  alt=""
                                  loading="lazy"
                                />
                              </a>
                            )}
                            {podcastData?.website && (
                              <a
                                className={`${styles.singleIconWrap} no-effect`}
                                href={podcastData?.website || '#'}
                                rel="noreferrer"
                                target="_blank"
                                style={{
                                  'pointer-events': podcastData?.website
                                    ? 'auto'
                                    : 'none',
                                }}>
                                <img
                                  src="/social-icons/website-icon.svg"
                                  className={styles.singleIcon}
                                  alt=""
                                  loading="lazy"
                                />
                              </a>
                            )}
                            {podcastData?.facebook_url && (
                              <a
                                className={`${styles.singleIconWrap} no-effect`}
                                href={podcastData?.facebook_url || '#'}
                                rel="noreferrer"
                                target="_blank"
                                style={{
                                  'pointer-events': podcastData?.facebook_url
                                    ? 'auto'
                                    : 'none',
                                }}>
                                <img
                                  src="/social-icons/facebook-icon.svg"
                                  className={styles.singleIcon}
                                  alt=""
                                  loading="lazy"
                                />
                              </a>
                            )}
                            {token && (
                              <a
                                className={`${styles.singleIconWrap} no-effect`}
                                href="#"
                                rel="noreferrer"
                                onClick={openSharingBox}>
                                <img
                                  src="/other-icons/share-icon.svg"
                                  className={`${styles.shareIcon} ${styles.singleIcon}`}
                                  alt=""
                                  loading="lazy"
                                />
                              </a>
                            )}
                            <CopyToClipboard
                              text={podcastData?.public_podcatcher_link}
                              onCopy={() => setCopied(true)}>
                              <Tooltip
                                open={copied}
                                onClose={() => setCopied(false)}
                                disableFocusListener
                                title={t(
                                  lang,
                                  'podcast',
                                )('feedCopiedInYourClipboard')}
                                arrow>
                                <img
                                  src="/other-icons/rss-feed.svg"
                                  className={`${styles.shareIcon} ${styles.singleIcon}`}
                                  alt=""
                                  loading="lazy"
                                  style={{ cursor: 'pointer' }}
                                />
                              </Tooltip>
                            </CopyToClipboard>
                          </div>
                          {token &&
                            showSubscribeButtons &&
                            podcastData?.annual_price_enabled &&
                            !podcastData?.cancel_at_period_end &&
                            isPodcast &&
                            (userSubscribed || podcastData?.user_subscribed) &&
                            !isAnnualSubscriber &&
                            podcastData?.coupon_data?.plan !== 'monthly' && (
                              <div className="col-12 col-lg-5 d-flex flex-column">
                                <>
                                  <Button
                                    variant="primary"
                                    onClick={subscribeConfirmation}
                                    className="compact"
                                    style={{
                                      background: podcastData?.button_color,
                                      color: podcastData?.text_color,
                                      borderColor: podcastData?.button_color,
                                      overflow: 'inherit',
                                      float: 'right',
                                      // marginTop: '-0.7rem',
                                    }}>
                                    {subBtnLoading ? (
                                      <Spinner animation="grow" />
                                    ) : (
                                      <span className="">
                                        {t(siteLang, 'podcast')(
                                          'upgradeToYearlyText',
                                          {
                                            price:
                                              discountedPriceObject?.annual_price
                                                ? discountedPriceObject?.annual_price
                                                : podcastData?.annual_price,
                                            currency,
                                          },
                                        )}
                                      </span>
                                    )}
                                  </Button>
                                  <div
                                    style={{
                                      textAlign: 'center',
                                      fontSize: '1.0rem',
                                      color: 'black',
                                      fontWeight: 'normal',
                                    }}
                                    className="mt-1">
                                    {t(siteLang, 'podcast')(
                                      'upgradeToYearlySavingMessage',
                                      {
                                        podcastData,
                                        currency,
                                      },
                                    )}
                                  </div>
                                </>
                              </div>
                            )}

                          {/* End of Upgrade to Annual Subscription section */}
                        </div>
                        <SharePopup
                          show={modalShow}
                          host={host}
                          modalTitle={
                            isPodcast
                              ? t(lang, 'common')('sharePodcast')
                              : t(lang, 'common')('shareAudio')
                          }
                          shareText={t(lang, 'common')('shareText', {
                            title: podcastData?.title,
                          })}
                          shareUrl={podcastData?.domain_redirect_url}
                          onHide={() => setModalShow(false)}
                        />
                        <div className={styles.podcasterDetails}>
                          <div className={styles.imageWrap}>
                            <img
                              className="img-fluid"
                              src={
                                podcastData?.creator_img ||
                                '/other-icons/user-icon.svg'
                              }
                              alt=""
                              loading="lazy"
                            />
                          </div>
                          <div className={styles.podcastContentWrap}>
                            <p className={styles.podcasterDetailsText}>
                              {t(siteLang, 'podcast')('hostedBy')}
                            </p>
                            {podcastData?.is_creator_published ? (
                              <Link href={podcastData?.creator_page_url || '#'}>
                                <a
                                  className={`${styles.mainTitleLink} no-effect`}>
                                  <h2 className={styles.podcasterName}>
                                    {podcastData?.creator_name}
                                  </h2>
                                </a>
                              </Link>
                            ) : (
                              <h2 className={styles.podcasterName}>
                                {podcastData?.creator_name}
                              </h2>
                            )}
                          </div>
                        </div>
                        {showSubscribeButtons &&
                          !(userSubscribed || podcastData.user_subscribed) &&
                          (listOfEpisode?.length > 0 || trailerEpisode) && (
                            <PodcastSubscription
                              lang={siteLang}
                              styles={styles}
                              subscribeToPodcast={
                                podcastData?.audio_type === 'podcast' &&
                                podcastData?.annual_price_enabled &&
                                !isCouponValid
                                  ? chooseSubscriptionPlan
                                  : subscribeConfirmation
                              }
                              podcastData={{
                                ...podcastData,
                                ...discountedPriceObject,
                              }}
                              isPodcast={isPodcast}
                              subBtnLoading={subBtnLoading}
                              currency={currency}
                              isEpisode={false}
                            />
                          )}
                        {token &&
                          (podcastData?.free_podcast ||
                            parseFloat(podcastData?.price) === 0 ||
                            userSubscribed ||
                            podcastData?.user_subscribed) && (
                            <div
                              className={`${styles.cta} d-flex justify-content-between flex-wrap`}>
                              {/* <Link > */}
                              <a
                                href={
                                  podcastData?.podcatcher_redirect_link || '#'
                                }
                                className="btn btn-primary compact mb-3">
                                {t(siteLang, 'podcast')('addToYourPodcatcher')}
                              </a>
                              {/* </Link> */}
                              <Button
                                onClick={() =>
                                  Router.push(routes.podcastContact(slug))
                                }
                                className="compact"
                                variant="outline-primary">
                                {t(siteLang, 'podcast')('contactPodcaster')}
                              </Button>
                            </div>
                          )}
                        {token &&
                          showSubscribeButtons &&
                          podcastData?.cancel_at_period_end && (
                            <>
                              <div className={styles.renewInfo}>
                                {t(siteLang, 'common')('renewText', {
                                  date: new Date(
                                    podcastData?.subscription_end_date,
                                  ).toDateString(),
                                })}
                              </div>
                              <Button
                                onClick={handleRenewSubscription}
                                variant="primary"
                                className="compact"
                                disabled={subBtnLoading}
                                style={{
                                  background: podcastData?.button_color,
                                  color: podcastData?.text_color,
                                  borderColor: podcastData?.button_color,
                                }}>
                                {subBtnLoading ? (
                                  <Spinner animation="grow" />
                                ) : (
                                  t(siteLang, 'podcast')('renewSubscribe', {
                                    podcastPrice: podcastData?.price,
                                    currency,
                                  })
                                )}
                              </Button>
                            </>
                          )}
                      </div>
                    </Col>
                  </Row>

                  <p
                    className={styles.podcastDescription}
                    dangerouslySetInnerHTML={{
                      __html: podcastData.description,
                    }}></p>

                  {showListenNowSection && (
                    <div
                      className={`${styles.socialIconsWrap} ${styles.listenNowWrap}`}>
                      <h3 className={styles.subheading}>
                        {t(siteLang, 'podcast')('listenNowOn')}
                      </h3>
                      <div className="mt-4">
                        {podcastData?.apple_podcast && (
                          <a
                            className={`${styles.singleIconWrap} no-effect`}
                            href={podcastData?.apple_podcast || '#'}
                            rel="noreferrer"
                            target="_blank"
                            style={{
                              'pointer-events': podcastData?.apple_podcast
                                ? 'auto'
                                : 'none',
                            }}>
                            <img
                              src="/player-icons/apple-podcasts.svg"
                              className={styles.singleIcon}
                              alt=""
                              loading="lazy"
                            />
                          </a>
                        )}
                        {podcastData?.spotify && (
                          <a
                            className={`${styles.singleIconWrap} no-effect`}
                            href={podcastData?.spotify || '#'}
                            rel="noreferrer"
                            target="_blank"
                            style={{
                              'pointer-events': podcastData?.spotify
                                ? 'auto'
                                : 'none',
                            }}>
                            <img
                              src="/player-icons/spotify.svg"
                              className={styles.singleIcon}
                              alt=""
                              loading="lazy"
                            />
                          </a>
                        )}
                        {podcastData?.overcast && (
                          <a
                            className={`${styles.singleIconWrap} no-effect`}
                            href={podcastData?.overcast || '#'}
                            rel="noreferrer"
                            target="_blank"
                            style={{
                              'pointer-events': podcastData?.overcast
                                ? 'auto'
                                : 'none',
                            }}>
                            <img
                              src="/player-icons/overcast.svg"
                              className={styles.singleIcon}
                              alt=""
                              loading="lazy"
                            />
                          </a>
                        )}
                        {podcastData?.ivoox && (
                          <a
                            className={`${styles.singleIconWrap} no-effect`}
                            href={podcastData?.ivoox || '#'}
                            rel="noreferrer"
                            target="_blank"
                            style={{
                              'pointer-events': podcastData?.ivoox
                                ? 'auto'
                                : 'none',
                            }}>
                            <img
                              src="/player-icons/ivoox.svg"
                              className={styles.singleIcon}
                              alt=""
                              loading="lazy"
                            />
                          </a>
                        )}
                        {podcastData?.youtube && (
                          <a
                            className={`${styles.singleIconWrap} no-effect`}
                            href={podcastData?.youtube || '#'}
                            rel="noreferrer"
                            target="_blank"
                            style={{
                              'pointer-events': podcastData?.youtube
                                ? 'auto'
                                : 'none',
                            }}>
                            <img
                              src="/player-icons/youtube.svg"
                              className={styles.singleIcon}
                              alt=""
                              loading="lazy"
                            />
                          </a>
                        )}
                      </div>
                    </div>
                  )}
                  {trailerEpisode && (
                    <div className={styles.singleEpisodeCard}>
                      <div
                        className={`${styles.leftCover} ${styles.play}`}
                        onClick={() => playEpisode(trailerEpisode)}
                        id={`trailer${trailerEpisode?.id}PlayButton`}>
                        <div
                          className={`${styles.featuredImage}`}
                          style={{
                            backgroundImage: `url('${trailerEpisode?.thumbnail}')`,
                          }}
                        />
                        {userData?.episodeDetail?.id === trailerEpisode?.id &&
                        userData?.audioPlayStatus ? (
                          <div
                            id={`trailer${trailerEpisode?.id}PauseIcon`}
                            className="pause-icon"
                          />
                        ) : (
                          <div
                            id={`trailer${trailerEpisode?.id}PlayIcon`}
                            className="play-icon"
                          />
                        )}
                      </div>
                      <div className={styles.rightDetails}>
                        <Link href={trailerEpisode?.domain_redirect_url || '#'}>
                          <a>
                            <h3 className={styles.title}>
                              {t(siteLang, 'podcast')('trailer')}
                            </h3>
                          </a>
                        </Link>
                        <p className={styles.description}>
                          {trailerEpisode?.short_description?.substring(0, 130)}
                          {trailerEpisode?.short_description?.length >= 130 &&
                            '...'}
                        </p>
                        {trailerEpisode?.short_description?.length > 130 && (
                          <Link
                            href={trailerEpisode?.domain_redirect_url || '#'}>
                            <a>{t(siteLang, 'podcast')('readMore')}</a>
                          </Link>
                        )}
                        {/* <p className={styles.free}>Free</p> */}
                      </div>
                    </div>
                  )}

                  {listOfEpisode?.length > 0 ? (
                    <h2 className={styles.subheading}>
                      {isPodcast
                        ? t(siteLang, 'podcast')('episodes')
                        : t(siteLang, 'account')('audios')}
                    </h2>
                  ) : (
                    <h2>
                      {isPodcast
                        ? t(siteLang, 'podcast')('noEpisodesFound')
                        : t(siteLang, 'podcast')('noAudiosFound')}
                    </h2>
                  )}
                  {listOfEpisode?.slice(0, 3).map((item, index) => (
                    <div
                      className={styles.singleEpisodeCard}
                      key={item?.id}
                      ref={
                        listOfEpisode?.length === index + 1
                          ? lastAudioElementRef
                          : null
                      }
                      data-id={
                        listOfEpisode?.length === index + 1
                          ? 'lastAudioElementRef'
                          : null
                      }
                      id={item?.id}>
                      {!podcastData?.stop_charging_user ||
                      (podcastData?.stop_charging_user &&
                        (item?.access_content ||
                          (!item?.access_content &&
                            item?.promo_presined_url))) ? (
                        <div
                          className={`${styles.leftCover} ${styles.play}`}
                          onClick={() => playEpisode(item)}
                          id={`episode${item?.id}PlayButton`}>
                          <div
                            className={`${styles.featuredImage}`}
                            style={{
                              backgroundImage: `url('${item?.thumbnail}')`,
                            }}
                          />
                          {userData?.episodeDetail?.id === item?.id &&
                          userData?.audioPlayStatus ? (
                            <div
                              id={`episode${item?.id}PauseIcon`}
                              className="pause-icon"
                            />
                          ) : (
                            <div
                              id={`episode${item?.id}PlayIcon`}
                              className="play-icon"
                            />
                          )}
                        </div>
                      ) : (
                        <div className={`${styles.leftCover}`}>
                          <div
                            className={`${styles.featuredImage}`}
                            style={{
                              backgroundImage: `url('${item?.thumbnail}')`,
                            }}
                          />
                        </div>
                      )}
                      <div className={styles.rightDetails}>
                        <Link href={item?.domain_redirect_url || '#'}>
                          <a>
                            <h3 className={styles.title}>{item?.name}</h3>
                          </a>
                        </Link>
                        <span
                          style={{ fontSize: '1rem' }}
                          className={styles.description}>
                          {new Date(item?.published_at).toLocaleDateString()} -{' '}
                          {item?.duration}
                        </span>

                        <p className={styles.description}>
                          {item?.short_description?.substring(0, 130)}
                          {item?.short_description?.length > 130 && '...'}
                        </p>
                        {item?.short_description?.length > 130 && (
                          <Link href={item?.domain_redirect_url || '#'}>
                            <a>{t(siteLang, 'podcast')('readMore')}</a>
                          </Link>
                        )}
                        {showSubscribeButtons ? (
                          item?.type === 1 &&
                          !(
                            (token && userSubscribed) ||
                            podcastData?.user_subscribed
                          ) ? (
                            ''
                          ) : (token && userSubscribed) ||
                            podcastData?.user_subscribed ? (
                            ''
                          ) : (
                            <p className={styles.free}>
                              {t(siteLang, 'podcast')('free')}
                            </p>
                          )
                        ) : item?.type === 1 ||
                          (token && userSubscribed) ||
                          podcastData?.user_subscribed ? (
                          ''
                        ) : (
                          <p className={styles.free}>
                            {t(siteLang, 'podcast')('free')}
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                  {listOfEpisode?.length > 0 &&
                    showSubscribeButtons &&
                    !(
                      (token && userSubscribed) ||
                      podcastData?.user_subscribed
                    ) && (
                      <div className={styles.bigCtaWrap}>
                        <a
                          href="#"
                          onClick={
                            podcastData?.audio_type === 'podcast' &&
                            podcastData?.annual_price_enabled &&
                            !isCouponValid
                              ? chooseSubscriptionPlan
                              : subscribeConfirmation
                          }
                          className={`${styles.ctaLink} no-effect`}>
                          <div className={styles.bigCta}>
                            <img
                              className={`${styles.mobileIcon} d-lg-none img-fluid`}
                              src="/favicon.webp"
                              alt="Mumbler icon"
                            />
                            <p className={styles.text}>
                              {isPodcast
                                ? t(
                                    siteLang,
                                    'podcast',
                                  )('onlyAvailableForSubscribersSubscribe')
                                : t(
                                    siteLang,
                                    'podcast',
                                  )('onlyAvailableForPurchaserCustomers')}{' '}
                              <span className="color-secondary">
                                {t(siteLang, 'podcast')('now')}
                              </span>
                              !
                            </p>
                          </div>
                        </a>
                      </div>
                    )}
                  {listOfEpisode?.length > 0 &&
                    listOfEpisode
                      ?.slice(3, listOfEpisode?.length)
                      .map((item, index) => (
                        <div
                          key={item?.id}
                          ref={
                            listOfEpisode?.length === index + 4
                              ? lastAudioElementRef
                              : null
                          }
                          id={item?.id}
                          className={styles.singleEpisodeCard}>
                          {!podcastData?.stop_charging_user ||
                          (podcastData?.stop_charging_user &&
                            (item?.access_content ||
                              (!item?.access_content &&
                                !!item?.promo_presined_url))) ? (
                            <div
                              className={`${styles.leftCover} ${styles.play}`}
                              onClick={() => playEpisode(item)}
                              id={`episode${item?.id}PlayButton`}>
                              <div
                                className={`${styles.featuredImage}`}
                                style={{
                                  backgroundImage: `url('${item?.thumbnail}')`,
                                }}
                              />
                              {userData?.episodeDetail?.id === item?.id &&
                              userData?.audioPlayStatus ? (
                                <div
                                  id={`episode${item?.id}PauseIcon`}
                                  className="pause-icon"
                                />
                              ) : (
                                <div
                                  id={`episode${item?.id}PlayIcon`}
                                  className="play-icon"
                                />
                              )}
                            </div>
                          ) : (
                            <div className={`${styles.leftCover}`}>
                              <div
                                className={`${styles.featuredImage}`}
                                style={{
                                  backgroundImage: `url('${item?.thumbnail}')`,
                                }}
                              />
                            </div>
                          )}
                          <div className={styles.rightDetails}>
                            <Link href={item?.domain_redirect_url || '#'}>
                              <a>
                                <h3 className={styles.title}>{item?.name}</h3>
                              </a>
                            </Link>
                            <span
                              style={{ fontSize: '1rem' }}
                              className={styles.description}>
                              {new Date(
                                item?.published_at,
                              ).toLocaleDateString()}{' '}
                              - {item?.duration}
                            </span>
                            <p className={styles.description}>
                              {item?.short_description?.substring(0, 130)}
                              {item?.short_description?.length > 130 && '...'}
                            </p>
                            {item?.short_description?.length > 130 && (
                              <Link href={item?.domain_redirect_url || '#'}>
                                <a>{t(siteLang, 'podcast')('readMore')}</a>
                              </Link>
                            )}
                            {showSubscribeButtons ? (
                              item?.type === 1 &&
                              !(
                                (token && userSubscribed) ||
                                podcastData?.user_subscribed
                              ) ? (
                                ''
                              ) : (token && userSubscribed) ||
                                podcastData?.user_subscribed ? (
                                ''
                              ) : (
                                <p className={styles.free}>
                                  {t(siteLang, 'podcast')('free')}
                                </p>
                              )
                            ) : item?.type === 1 ||
                              (token && userSubscribed) ||
                              podcastData?.user_subscribed ? (
                              ''
                            ) : (
                              <p className={styles.free}>
                                {t(siteLang, 'podcast')('free')}
                              </p>
                            )}
                          </div>
                        </div>
                      ))}

                  {loading && renderSpinner()}

                  <div
                    className={`${styles.buttonsWrapper} ${styles.multipleButtons} compact`}>
                    {token &&
                      podcastData?.audio_type === 'podcast' &&
                      (userSubscribed || podcastData?.user_subscribed) &&
                      !podcastData?.cancel_at_period_end && (
                        <p className={`mx-0 mr-sm-2 ${styles.loadMore}`}>
                          <Button
                            variant="outline-danger"
                            onClick={unSubscribeConfirmation}
                            className="compact">
                            {t(siteLang, 'common')('unsubscribe')}
                          </Button>
                        </p>
                      )}

                    {/* {enableMoreBtn && (
                      <p className={`${styles.loadMore} ml-auto`}>
                        <Button
                          variant="outline-primary"
                          onClick={fetchMoreEpisodes}
                          className="compact"
                          disabled={!enableMoreBtn}>
                          {isPodcast
                            ? t(siteLang, 'podcast')('moreEpisodes')
                            : t(siteLang, 'podcast')('moreAudios')}
                        </Button>
                      </p>
                    )} */}
                    {token &&
                      (userSubscribed || podcastData?.user_subscribed) && (
                        <p className={`mx-0 ml-sm-2 ${styles.loadMore}`}>
                          <Button
                            onClick={() =>
                              Router.push(routes.podcastContact(slug))
                            }
                            className="compact ml-auto"
                            variant="outline-primary">
                            {t(siteLang, 'podcast')('contactPodcaster')}
                          </Button>
                        </p>
                      )}
                  </div>
                </Container>

                <PublicFooter langChanger={false} lang={lang} />

                <ConfirmationPopup
                  showConfirmation={showConfirmationBox}
                  handleClose={() => {
                    if (
                      process.env.NEXT_PUBLIC_FRONTEND_URL ===
                      'http://localhost:3000'
                    ) {
                      Cookies.remove('subscriptionConfirmation', { path: '/' });
                    } else {
                      Cookies.remove('subscriptionConfirmation', {
                        path: '/',
                        domain: '.mumbler.io',
                      });
                    }
                    setShowConfirmationBox(false);
                  }}
                  title={message?.title}
                  message={message?.message}
                  noNeedOfAlertIcon={
                    message?.type === 'subscribe' ||
                    message?.type === 'purchase'
                  }
                  disableSubmitButton={unsubscribeLoading}
                  confirmActionCallback={
                    message?.type === 'subscribe' ||
                    message?.type === 'purchase'
                      ? message?.cardExpiresMessage
                        ? updateCreditCard
                        : () => {
                            if (
                              isPodcast &&
                              (userSubscribed ||
                                podcastData?.user_subscribed) &&
                              !isAnnualSubscriber
                            ) {
                              planTypeRef.current = 'yearly';
                              if (
                                process.env.NEXT_PUBLIC_APP_FRONTEND_URL ===
                                'http://localhost:3000'
                              ) {
                                Cookies.set(
                                  'plan_interval',
                                  planTypeRef.current,
                                  { path: '/' },
                                );
                              } else {
                                Cookies.set(
                                  'plan_interval',
                                  planTypeRef.current,
                                  { path: '/', domain: '.mumbler.io' },
                                );
                              }
                              upgradeSubscription(
                                podcastData,
                                planTypeRef?.current,
                                isCouponValid ? coupon : null,
                              );
                            } else {
                              if (podcastData?.coupon_data?.plan === 'annual') {
                                planTypeRef.current = 'yearly';
                                if (
                                  process.env.NEXT_PUBLIC_APP_FRONTEND_URL ===
                                  'http://localhost:3000'
                                ) {
                                  Cookies.set(
                                    'plan_interval',
                                    planTypeRef.current,
                                    { path: '/' },
                                  );
                                } else {
                                  Cookies.set(
                                    'plan_interval',
                                    planTypeRef.current,
                                    { path: '/', domain: '.mumbler.io' },
                                  );
                                }
                              }
                              subscribeToPodcast(
                                podcastData,
                                planTypeRef?.current,
                                isCouponValid ? coupon : null,
                              );
                            }
                          }
                      : unSubscribe
                  }
                  cancelButton={
                    message?.type === 'unsubscribe' &&
                    t(siteLang, 'podcast')('keepSubscription')
                  }
                  submitButton={
                    ((message?.type === 'subscribe' ||
                      message?.type === 'purchase') &&
                      message?.cardExpiresMessage &&
                      t(siteLang, 'podcast')('updateCreditCardButton')) ||
                    (message?.type === 'subscribe' &&
                      !message?.cardExpiresMessage &&
                      t(siteLang, 'podcast')('subscribeButton')) ||
                    (message?.type === 'unsubscribe' &&
                      t(siteLang, 'podcast')('unsubscribeButton')) ||
                    (message?.type === 'purchase' &&
                      !message?.cardExpiresMessage &&
                      t(siteLang, 'podcast')('purchaseButton'))
                  }
                  submitButtonColor={
                    (message?.type === 'subscribe' ||
                      message?.type === 'purchase') &&
                    '#0d122a'
                  }
                />
                <MessagePopup
                  title=""
                  message={t(siteLang, 'podcast')('noSubscriptionsNow')}
                  isOpen={showSubscriptionMessage}
                  hideModal={() => {
                    setShowSubscriptionMessage(false);
                  }}
                />
                <Modal
                  dialogClassName="modal-dialog-wide"
                  show={showPlanSelectModal}
                  onHide={() => {
                    setShowPlanSelectModal(false);
                  }}>
                  <Modal.Body>
                    <h4
                      style={{
                        textAlign: 'center',
                      }}>
                      {t(siteLang, 'podcast')('chooseYourPlan')}
                    </h4>
                    <div className="d-flex flex-column px-4">
                      <Button
                        variant="primary"
                        className="compact mb-4"
                        onClick={() => {
                          setShowPlanSelectModal(false);
                          planTypeRef.current = 'monthly';
                          if (
                            process.env.NEXT_PUBLIC_APP_FRONTEND_URL ===
                            'http://localhost:3000'
                          ) {
                            Cookies.set('plan_interval', planTypeRef?.current, {
                              path: '/',
                            });
                          } else {
                            Cookies.set('plan_interval', planTypeRef?.current, {
                              path: '/',
                              domain: '.mumbler.io',
                            });
                          }
                          subscribeConfirmation();
                        }}
                        style={{
                          background: podcastData?.button_color,
                          color: podcastData?.text_color,
                          borderColor: podcastData?.button_color,
                        }}>
                        {t(siteLang, 'podcast')(
                          'planPopupSubscribeMonthlyMessage',
                          {
                            podcastData: {
                              ...podcastData,
                              ...discountedPriceObject,
                            },
                            currency,
                          },
                        )}
                      </Button>
                      <Button
                        variant="primary"
                        className="compact mb-4"
                        onClick={() => {
                          setShowPlanSelectModal(false);
                          planTypeRef.current = 'yearly';
                          if (
                            process.env.NEXT_PUBLIC_APP_FRONTEND_URL ===
                            'http://localhost:3000'
                          ) {
                            Cookies.set('plan_interval', planTypeRef?.current, {
                              path: '/',
                            });
                          } else {
                            Cookies.set('plan_interval', planTypeRef?.current, {
                              path: '/',
                              domain: '.mumbler.io',
                            });
                          }
                          subscribeConfirmation();
                        }}
                        style={{
                          background: podcastData?.button_color,
                          color: podcastData?.text_color,
                          borderColor: podcastData?.button_color,
                          overflow: 'inherit',
                        }}>
                        {t(siteLang, 'podcast')(
                          'planPopupSubscribeYearlyMessage',
                          {
                            podcastData: {
                              ...podcastData,
                              ...discountedPriceObject,
                            },
                            currency,
                          },
                        )}
                      </Button>
                      <div
                        style={{
                          textAlign: 'center',
                          fontSize: '1.0rem',
                          color: 'black',
                          fontWeight: 'normal',
                          marginTop: '-0.8rem',
                        }}>
                        {t(siteLang, 'podcast')(
                          'planPopupSubscribeYearlySavings',
                          {
                            podcastData: {
                              ...podcastData,
                              ...discountedPriceObject,
                            },
                          },
                        )}
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </Col>
            </Row>
          </Container>
        </main>
      )}
    </div>
  );
};

PodcastPage.propTypes = {
  podcastData: PropTypes.object.isRequired,
  episodeData: PropTypes.object.isRequired,
  slug: PropTypes.string,
  token: PropTypes.string,
  isPodcast: PropTypes.bool,
  subscriptionConfirmation: PropTypes.bool,
  host: PropTypes.string,
  spotifyShowUri: PropTypes.string,
};

export default PodcastPage;
